import React from "react"
import { Link } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"

const ThankYouPage = () => {
  return (
    <Layout>
      {/*<SEO title={page.title} />*/}
      <SEO title="Thank you!" />
      <div className="page-headline">
        <div className="container">
          <h1>Thank you!</h1>
        </div>
      </div>
      <div className="page-body">
        <div className="container">
          <div className="my-5 text-center">
            <p className="lead mb-4">
              We have received your message and will get back to you shortly.
            </p>
            <Link to="/" className="btn btn-primary btn-red">
              Return Home
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ThankYouPage
